<template>
  <div class="container-fluid">
    <div class="row mb-3">
      <div class="col my-auto">
        <h4 class="text-primary fw-bold mb-0">
          Website Snippets
          <span class="badge bg-info">BETA</span>
        </h4>
      </div>
    </div>
    <h4 class="card-title fw-bold">Integrating the Enquiry Form</h4>

    <div class="row mb-2">
      <div class="col">
        <pre class="text-danger" id="enquiryToCopy">
&lt;swandoola-enquiry-form clinic="{{
            user.clinic_id
          }}"&gt;&lt;/swandoola-enquiry-form&gt;</pre
        >
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-auto ms-auto">
        <button
          class="btn btn-sm btn-outline-primary"
          v-clipboard:copy="enquiryStringToCopy"
        >
          <i class="far fa-copy me-2"></i>Copy to clipboard
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      enquiryStringToCopy: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    }
  },
  mounted() {
    this.enquiryStringToCopy = document.getElementById(
      "enquiryToCopy"
    ).textContent;
  },
};
</script>

<style>
</style>